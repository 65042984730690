import { Common, Account } from '../api';
import { ActionCreatorsMapObject, Reducer } from 'redux';
import { LoadProperty, AddLocalProperty } from './save-state';

// Tipo de acciones.
export enum ActionTypes {
    loadRequest = 'ACCOUNT_DATA_REQUEST',
    loadComplete = 'ACCOUNT_DATA_COMPLETED',
    loadError = 'ACCOUNT_DATA_ERROR',
    remember = 'ACCOUNT_REMEMBER',
    updateAvatar = 'ACCOUNT_UPDATEAVATAR',
    unloadInfo = 'ACCOUNT_UNLOAD'
}

// Definiciones.
export interface IState {
    readonly inLoading: boolean;
    readonly isLoaded: boolean;
    readonly remember: boolean;
    readonly userID?: string;
    readonly name?: string;
    readonly eMail?: string;
    readonly role: Common.UserRole,
    readonly hasModuleInCharge: boolean;
    readonly avatarCount: number,
}

export interface IActions extends ActionCreatorsMapObject {
    loadInfo: any; // StoreActionCreator<ActionTypes, boolean>;
    changeRemember: any; // StoreActionCreator<ActionTypes, void>;
    updateAvatar: any;
    unloadInfo: any;
}

// Acciones.
export const actions: IActions = {
    loadInfo: () => async (dispatch: any, getState: any) => {
        const state = getState();
        if (state.authorization.isLogin) {
            dispatch({ type: ActionTypes.loadRequest })
            const response = await Account.getInformation();
            if (response.status === 'OK') {
                dispatch({
                    type: ActionTypes.loadComplete,
                    payload: {
                        userID: response.payload.userID,
                        name: response.payload.name,
                        eMail: response.payload.eMail,
                        role: response.payload.role,
                        hasModuleInCharge: response.payload.hasModuleInCharge,
                        avatarCount: state.account.avatarCount + 1,
                    }
                });
                return true;
            } else {
                dispatch({ type: ActionTypes.loadError });
                return false;
            }
        }
        return false;
    },
    changeRemember: (remember: boolean) => async (dispatch: any, getState: any) => {
        dispatch({ type: ActionTypes.remember, payload: { remember } });
    },
    updateAvatar: () => async (dispatch: any, getState: any) => {
        const state = getState();
        dispatch({
            type: ActionTypes.updateAvatar, payload: { count: state.account.avatarCount + 1 }
        });
    },
    unloadInfo: () => async (dispatch: any, getState: any) => {
        dispatch({ type: ActionTypes.unloadInfo });
    }
};

// Función reductora.
export const reducer: Reducer<IState, any> = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case ActionTypes.loadRequest: {
            return {
                ...state,
                inLoading: true
            }
        }
        case ActionTypes.loadComplete: {
            return {
                ...state,
                userID: action.payload.userID,
                name: action.payload.name,
                eMail: action.payload.eMail,
                role: action.payload.role,
                hasModuleInCharge: action.payload.hasModuleInCharge,
                avatarCount: action.payload.avatarCount,
                isLoaded: true,
                inLoading: false
            }
        }
        case ActionTypes.loadError: {
            return {
                ...state,
                userID: undefined,
                name: undefined,
                eMail: undefined,
                role: Common.UserRole.Public,
                isLoaded: false,
                inLoading: false,
                remember: false,
                hasModuleInCharge: false
            }
        }
        case ActionTypes.remember: {
            return {
                ...state,
                remember: action.payload.remember
            }
        }
        case ActionTypes.updateAvatar: {
            return {
                ...state,
                avatarCount: action.payload.count,
            }
        }
        case ActionTypes.unloadInfo: {
            return {
                ...state,
                isLoaded: false
            }
        }
        default:
            return state;
    }
}

// Estado inicial.
const rememberProp: boolean = LoadProperty('AccountRemember', false);

const initialState: IState = {
    remember: rememberProp,
    inLoading: false,
    isLoaded: false,
    userID: undefined,
    name: undefined,
    role: Common.UserRole.Public,
    hasModuleInCharge: false,
    eMail: rememberProp ? LoadProperty('AccountEMail') : undefined,
    avatarCount: 0
}

AddLocalProperty('AccountRemember', state => state.account.remember);
AddLocalProperty('AccountEMail', state => state.account.eMail);
