import * as Connection from './connection';
import * as Common from './common';

export interface IScope {
    scopeID: string;
    name: string;
    version: string;
    apiUrl: string;
    defaultWebUrl: string;
}

export async function scopes() {
    const result = await Connection.get('/Public/Scopes', true);
    return result.data as Common.IResponse<IScope[]>;
}

export async function scope(scopeID: string) {
    const result = await Connection.post('/Public/Scope', { scopeID }, true)
    return result.data as Common.IResponse<IScope>;
}

export async function findScope(url: string) {
    const result = await Connection.post('/Public/FindScope', { url }, true);
    return result.data as Common.IResponse<IScope>;
}
