import * as Connection from './connection';
import * as Common from './common';

export interface ISurveyInfo {
    surveyID: number;
    title: string;
    startDate: Date;
    endDate: Date;
    isAnswered: boolean;
    canEdit: boolean;
    canAnswer: boolean;
    canDelete: boolean;
    canViewResults: boolean;
    requireGeoposition: boolean;
    questions: IQuestion[];
}

export interface ISurveyList extends Common.IPagination<ISurveyInfo> {
    moduleID: number;
    canCreate: boolean;
}

export interface IQuestionParams {
    [id: string]: string;
}

export type QuestionType = 'BOOLEAN' | 'NUMBER' | 'RANGE' | 'FILE' | 'IMAGE' | 'TEXT' | 'RADIO' | 'CHECKS' | 'UNKNOWN';

export interface IQuestion {
    questionID: number;
    type: QuestionType;
    sequenceOrder: number;
    description: string;
    isOptional: boolean;
    params: IQuestionParams;
}

export interface IEditSurvey {
    surveyID: number;
    moduleID: number;
    title: string;
    description: string;
    requireGeoposition: boolean;
    startDate: Date;
    endDate: Date;
    questions: IQuestion[];
    targets: string[];
}

export async function getList(moduleID: number, page: number, itemsPerPage?: number) {
    const response = await Connection.post('/Survey/GetList', { moduleID, page, itemsPerPage });
    return response.data as Common.IResponse<ISurveyList>
}

export async function edit(surveyID: number) {
    const response = await Connection.get('/Survey/Edit', { surveyID });
    return response.data as Common.IResponse<IEditSurvey>;
}

export async function save(survey: IEditSurvey) {
    const response = await Connection.post('/Survey/Save', { ...survey });
    return response.data as Common.EmptyResponse;
}

export async function remove(surveyID: number) {
    const response = await Connection.post('/Survey/Remove', { surveyID });
    return response.data as Common.EmptyResponse;
}
