import { Common, Connection } from '.';

export interface IStatistics {
    activeCount: number;
    clasificationsCount: number;
    hiddenClasificationsCount: number;
    elementsCount: number;
}

export interface IModuleInfo {
    moduleID: number;
    name: string;
    color: string;
    kind: Common.ModuleKind;
}

export interface IGetList {
    modules: IModuleInfo[];
}

export interface ISubModule {
    moduleID: number;
    name: string;
    description?: string;
    hidden: boolean;
    elementsCount: number;
    subModules: ISubModule[];
    responsibles: string[];
    subordinates: string[];
    deleted?: boolean;
}

export interface IEntity {
    id: string;
    name: string;
    isDisabled: boolean;
    isUser: boolean;
}

export interface IEdit {
    kind: Common.ModuleKind,
    color: string;
    innerModule: ISubModule;
}

export interface IGetAvailableEntitiesForModule {
    entities: IEntity[];
}

export async function getStatistics() {
    const response = await Connection.get('/Admin/Module/GetStatistics');
    return response.data as Common.IResponse<IStatistics>;
}

export async function getList() {
    const response = await Connection.get('/Admin/Module/GetList');
    return response.data as Common.IResponse<IGetList>;
}

export async function getAvailableEntitiesForModule() {
    const response = await Connection.get('/Admin/Module/GetAvailableEntitiesForModule');
    return response.data as Common.IResponse<IGetAvailableEntitiesForModule>;
}

export async function edit(moduleID: number) {
    const response = await Connection.post('/Admin/Module/Edit', { moduleID });
    return response.data as Common.IResponse<IEdit>;
}

export async function save(module: IEdit) {
    const response = await Connection.post('/Admin/Module/Save', { ...module });
    return response.data as Common.EmptyResponse;
}