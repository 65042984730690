import Axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { setTimeout } from 'timers';
import * as Common from './common';
import { history } from '../index';

// Url inicial para el uso de la API.
const defaultBaseUrl: string = (process.env.NODE_ENV === 'production') ? 'https://app.myvoz.cl/API/' : 'https://localhost:44300/API/';

const hubLogLevel: LogLevel = (process.env.NODE_ENV === 'production') ? LogLevel.Debug : LogLevel.Information;

// Url actual de la API.
let currentBaseUrl: string = defaultBaseUrl;

// Configuración para Axios.
let config: AxiosRequestConfig = {
    validateStatus: (status: number) => {
        switch (status) {
            case 400:
            case 404:
                history.replace('/error/notfound');
                return false;
        }
        return true;
    },
    headers: {}
};

// Último token de autorización Bearer.
let lastAuthorizationToken: string = '';

function sleep(ms: number) {
    return new Promise(resolver => setTimeout(resolver, ms));
}

// Función para enviar el token a HubConnetion.
function getAuthorizationToken() {
    return lastAuthorizationToken;
}


export function setScope(scope?: string) {
    if (scope) {
        currentBaseUrl = scope;
    } else {
        currentBaseUrl = defaultBaseUrl;
    }

}

export function setAuthorization(token?: string | null) {
    if (token === undefined || token === null) {
        delete config.headers.Authorization;
        lastAuthorizationToken = '';
    } else {
        config = {
            ...config,
            headers: { ...config.headers, 'Authorization': 'Bearer ' + token }
        };
        lastAuthorizationToken = token;
    }
}

export function get<T>(url: string, params: any = {}, forcePublic: boolean = false): AxiosPromise<T> {
    return Axios.request({
        ...config,
        method: 'get',
        baseURL: forcePublic ? defaultBaseUrl : currentBaseUrl,
        url,
        params
    });
}
export function post<T>(url: string, params: any = {}, forcePublic: boolean = false): AxiosPromise<T> {
    return Axios.request({
        ...config,
        method: 'post',
        baseURL: forcePublic ? defaultBaseUrl : currentBaseUrl,
        url,
        data: params
    });
}

export function getDownloadUrl(name: string, id: string | number, opt?: string | number | Date): string {
    let url = defaultBaseUrl + `Public/GetFile?name=${name}&id=${id}`;
    if (opt !== undefined) {
        url = url + `&opt=${opt}`;
    }
    return url;
}

export async function uploadFile(file: any, filename: string, progressCallback?: (e: any) => void) {
    const cfg = Object.apply(config, Object()) as AxiosRequestConfig;
    cfg.headers = { ...cfg.headers, 'Content-Type': 'multipart/form-data' };

    const formData = new FormData();
    formData.append('file', file, filename);

    const response = await Axios.request({
        ...cfg,
        baseURL: currentBaseUrl,
        method: 'post',
        url: 'Public/PutFile',
        data: formData,
        onUploadProgress: progressCallback
    });
    return response.data as Common.IResponse<Common.IUploadFile>;
}

export async function downloadFile(url: string, cnnCfg: AxiosRequestConfig = {}) {
    const response = await Axios.request({
        ...cnnCfg,
        method: 'get',
        url,
        responseType: 'blob'
    });
    if (response.status === 200) {
        return new Blob([response.data], { type: response.headers['content-type'] })
    } else {
        return null;
    }
}

export function getUploadUrl(): string {
    return defaultBaseUrl + `Public/PutFile`;
}

export async function getHubConnection(url: string): Promise<HubConnection> {
    while (lastAuthorizationToken === '') {
        await sleep(1000);
    }

    return new HubConnectionBuilder()
        .withUrl(defaultBaseUrl + url, { accessTokenFactory: getAuthorizationToken })
        .configureLogging(hubLogLevel)
        .build();
}