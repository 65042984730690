import * as Connection from './connection';
import * as Common from './common';

export interface ICategoryInfo {
    moduleID: number;
    name: string;
    description?: string;
}

export interface ILastPost {
    forumPostID: string;
    forumThreadID: string;
    postSubject: string;
    date: Date;
    userID: string;
}

interface IBoardInfoChild {
    moduleID: number;
    name: string;
}

export interface IBoardInfo {
    moduleID: number;
    name: string;
    description?: string;
    postCount: number;
    threadCount: number;
    subBoards: IBoardInfoChild[];
    lastPostInfo?: ILastPost;
}

export interface ICategories {
    moduleID: number;
    name: string;
    categories: ICategoryInfo[];
}

export interface IBoards {
    moduleID: number;
    name: string;
    boards: IBoardInfo[];
}

export interface IThreadInfo {
    forumThreadID: string;
    subject: string;
    createdByUserID: string;
    isPinned: boolean;
    isClosed: boolean;
    isHidden: boolean;
    postCount: number;
    hiddenPostCount: boolean;
    lastPost: ILastPost;
    canEdit: boolean;
}

export interface IBoard extends Common.IPagination<IThreadInfo> {
    moduleID: number;
    name: string;
    canAddThread: boolean;
    subBoards: IBoardInfo[];
}

export interface IPostInfo {
    forumPostID: string;
    subject: string;
    body: string;
    createdDate: Date;
    createdUserID: string;
    editedDate?: Date;
    editedUserID?: string

    isHidden: boolean;

    canEdit: boolean;
    canHide: boolean;
    hasReactions: boolean;
}

export interface IThread extends Common.IPagination<IPostInfo> {
    forumThreadID: string;
    moduleID: number;
    subject: string;
    canAdd: boolean;
}

export interface IEditThread {
    forumThreadID: string;
    moduleID: number;
    subject: string;
    body: string;
    hidden: boolean;
    pinned: boolean;
    closed: boolean;

    canHidden: boolean;
}

export interface IEditPost {
    forumPostID: string;
    forumThreadID: string;
    subject: string;
    body: string;
}

export interface ICalcPostPage {
    forumThreadID: string;
    page: number;
}

export async function getCategories(moduleID: number) {
    const response = await Connection.post('/Forum/GetCategories', { moduleID });
    return response.data as Common.IResponse<ICategories>;
}

export async function getBoards(moduleID: number) {
    const response = await Connection.post('/Forum/GetBoards', { moduleID });
    return response.data as Common.IResponse<IBoards>;
}

export async function getBoard(moduleID: number, page: number, itemsPerPage?: number, includeHidden?: boolean) {
    const response = await Connection.post('/Forum/GetBoard', { moduleID, page, itemsPerPage, includeHidden });
    return response.data as Common.IResponse<IBoard>;
}

export async function getBoardFromThread(threadID: string) {
    const response = await Connection.post('/Forum/GetBoardFromThread', { threadID });
    return response.data as Common.IResponse<{ boardID: number }>;
}

export async function getThread(forumThreadID: string, page: number, itemsPerPage?: number, includeHidden?: boolean) {
    const response = await Connection.post('/Forum/GetThread', { forumThreadID, page, itemsPerPage, includeHidden });
    return response.data as Common.IResponse<IThread>;

}

export async function editThread(forumThreadID: string) {
    const response = await Connection.post('/Forum/EditThread', { forumThreadID });
    return response.data as Common.IResponse<IEditThread>;

}

export async function saveThread(thread: IEditThread) {
    const response = await Connection.post('/Forum/SaveThread', { ...thread });
    return response.data as Common.EmptyResponse;
}

export async function editPost(forumPostID: string) {
    const response = await Connection.post('/Forum/EditPost', { forumPostID });
    return response.data as Common.IResponse<IEditPost>;

}

export async function savePost(post: IEditPost) {
    const response = await Connection.post('/Forum/SavePost', { ...post });
    return response.data as Common.EmptyResponse;
}

export async function calcPostPage(forumPostID: string) {
    const response = await Connection.post('/Forum/CalcPostPage', { forumPostID });
    return response.data as Common.IResponse<ICalcPostPage>;
}

export async function hiddenPost(forumPostID: string, hide: boolean) {
    const response = await Connection.post('/Forum/HiddenPost', { forumPostID, hide });
    return response.data as Common.EmptyResponse;
}