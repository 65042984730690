import * as Scope from './scope';
import * as Account from './account';
import * as Authorization from './authorization';

export interface IAppState {
    account: Account.IState;
    authorization: Authorization.IState;
    scope: Scope.IState;
};

export { Account, Authorization, Scope };
