import * as Connection from './connection';
import * as Common from './common';

export interface IStatistics {
    activeCount: number;
    disabledCount: number;
    requestedCount: number;
    preusersCount: number;
}

export interface IAvailableGroup {
    groupID: string;
    name: string;
    isDisabled: false;
}

interface IGetAvailableGroupForUser extends Array<{ groupID: string; name: string; isDisabled: boolean }> { };

interface IGetAvailableAlphabetFilter {
    letters: string;
}

export interface IScopedUserInfo {
    userID: string;
    name: string;
    eMail: string;
    role: Common.UserRole;
    isDisabled: boolean;
}

export interface IEdit {
    userID: string;
    name: string;
    eMail: string;
    role: Common.UserRole;
    hasAvatar: boolean;
    rut: number;
    address?: string;
    birthdate?: Date;
    gender?: Common.Gender;
    civilStatus?: Common.CivilStatus;
    isDisabled: boolean;
    loadedAvatar?: string;
    removeAvatar: boolean;
    groups: string[];
}

export interface IGetList extends Common.IAlphabeticPagination<IScopedUserInfo> {
    userRequestsCount: number;
}

export interface IEditUserRequest {
    userRequestID: number;
    rut: number,
    name: string,
    eMail: string,
    date: Date
}

export interface ISaveUserRequestItem {
    userRequestID: number;
    accept: boolean;
    role: Common.UserRole;
}

export async function getStatistics() {
    const response = await Connection.get('/Admin/User/GetStatistics');
    return response.data as Common.IResponse<IStatistics>;
}

export async function getAvailableAlphabetFilter() {
    const response = await Connection.get('/Admin/User/GetAvailableAlphabetFilter');
    return response.data as Common.IResponse<IGetAvailableAlphabetFilter>;
}

export async function getAvailableGroupsForUser() {
    const response = await Connection.get('/Admin/User/GetAvailableGroupsForUser');
    return response.data as Common.IResponse<IGetAvailableGroupForUser>;
}

export async function getList(page: number, filter?: string, itemsPerPage?: number) {
    const response = await Connection.post('/Admin/User/GetList', { filter, page, itemsPerPage });
    return response.data as Common.IResponse<IGetList>;
}

export async function edit(userID: string) {
    const response = await Connection.get('/Admin/User/Edit', { userID });
    return response.data as Common.IResponse<IEdit>;
}

export async function save(user: IEdit) {
    const response = await Connection.post('/Admin/User/Save', { ...user });
    return response.data as Common.EmptyResponse;
}

export async function editUserRequests() {
    const response = await Connection.get('/Admin/User/EditUserRequests');
    return response.data as Common.IResponse<IEditUserRequest[]>;
}

export async function saveUserRequests(requests: ISaveUserRequestItem[]) {
    const response = await Connection.post('/Admin/User/SaveUserRequests', { requests });
    return response.data as Common.IResponse<{ accepted: number, rejects: number }>;
}

export async function changeStatus(userID: string, disabled: boolean) {
    const response = await Connection.post('/Admin/User/ChangeStatus', { userID, disabled });
    return response.data as Common.EmptyResponse;
}

export async function invalidatePassword(userID: string) {
    const response = await Connection.post('/Admin/User/InvalidatePassword', { userID });
    return response.data as Common.EmptyResponse;
}