// tslint:disable: max-classes-per-file
export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

type ApiFunc<T> = () => Promise<IResponse<T>>;
type OkFunc<T> = (payload: T) => void;
type ErrorFunc = (error: IError[]) => void;

export async function run<T>(api: ApiFunc<T>, Ok: OkFunc<T>, Error?: ErrorFunc) {
    const response = await api();
    if (response.status === 'OK') {
        Ok(response.payload);
    } else {
        if (Error !== undefined) {
            Error(response.errors);
        }
    }
}

export function rebuildErrors(errors: IError[], values?: any) {
    const results: any = {};
    errors.forEach(x => {
        let value: any;
        if (values) {
            value = values[x.key];
        }
        results[x.key] = { value, errors: [] };
        x.values.forEach(e => {
            results[x.key].errors.push(new Error(e));
        });
    });

    return results;
}

export enum UserRole {
    GlobalAdministrator = 'globalAdministrator',
    Administrator = 'administrator',
    Functionary = 'functionary',
    Informer = 'informer',
    Public = 'public'
}

export class UserRoleUtils {
    public static getDisplayName(role: UserRole) {
        switch (role) {
            case UserRole.Public:
                return 'Público';
            case UserRole.Informer:
                return 'Informador';
            case UserRole.Functionary:
                return 'Funcionario';
            case UserRole.Administrator:
                return 'Administrador';
            case UserRole.GlobalAdministrator:
                return 'Administrador global';
        }
    }
}

export enum IssuePriority {
    Low = 'low',
    Normal = 'normal',
    High = 'high'
}

export class IssuePriorityUtils {
    public getDisplayName(priority: IssuePriority) {
        switch (priority) {
            case IssuePriority.Normal:
                return 'Normal';
            case IssuePriority.Low:
                return 'Baja';
            case IssuePriority.High:
                return 'Alta';
        }
    }
}

export enum IssueAction {
    Created = 'created',
    Assigned = 'assigned',
    Start = 'start',
    Stop = 'stop',
    Reject = 'reject',
    Close = 'close',
    Hidden = 'hidden'
}

export class IssueActionUtils {
    public getDisplayName(action: IssueAction) {
        switch (action) {
            case IssueAction.Created:
                return 'Creada';
            case IssueAction.Assigned:
                return 'Asignada';
            case IssueAction.Start:
                return 'Iniciada';
            case IssueAction.Stop:
                return 'Terminada';
            case IssueAction.Reject:
                return 'Rechazada';
            case IssueAction.Close:
                return 'Cerrada';
            case IssueAction.Hidden:
                return 'Oculta';
        }
    }
}

export enum ModuleKind {
    Issues = 'issues',
    Documents = 'documents',
    Forum = 'forum',
    Surveys = 'surveys',
    Classification = 'classification',
    Resources = 'resources',
}
export class ModuleKindUtils {
    public static getDisplayName(kind: ModuleKind) {
        switch (kind) {
            case ModuleKind.Classification:
                return 'Clasificación';
            case ModuleKind.Resources:
                return 'Recursos';
            case ModuleKind.Issues:
                return 'Incidencias';
            case ModuleKind.Documents:
                return 'Documentos';
            case ModuleKind.Forum:
                return 'Foros';
            case ModuleKind.Surveys:
                return 'Encuestas';
        }
    }
}

export enum IssueResolution {
    Resolved = 'resolved',
    Duplicated = 'duplicated',
    Wrong = 'wrong',
    Unresolved = 'unresolved'
}

export enum TrayKind {
    Inbox = 'inbox',
    Outbox = 'outbox',
    Trash = 'trash'
}

export enum Gender {
    Male = 'male',
    Female = 'female'
}

export class GenderUtils {
    public static getDisplayName(gender: Gender) {
        switch (gender) {
            case Gender.Male:
                return 'Masculino';
            case Gender.Female:
                return 'Femenino';
            default:
                return '';
        }
    }
}

export enum CivilStatus {
    Single = 'single',
    Married = 'married',
    Widower = 'widower',
    Divorced = 'divorced'
}

export class CivilStatusUtils {
    public static getDisplayName(civilStatus: CivilStatus) {
        switch (civilStatus) {
            case CivilStatus.Single:
                return 'Soltero';
            case CivilStatus.Married:
                return 'Casado';
            case CivilStatus.Widower:
                return 'Viudo';
            case CivilStatus.Divorced:
                return 'Separado';
        }
    }
}

export type Pagination = '#' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'Ñ' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'X' | 'Y' | 'Z' | undefined;

export interface IGeoPosition {
    latitude: number;
    longitude: number;
}

export interface IPagination<T> {
    page: number;
    totalPages: number;
    itemsPerPage: number;
    total: number;
    items: T[];
}

export interface IAlphabeticPagination<T> extends IPagination<T> {
    letter: Pagination;
}

export interface IError {
    key: string;
    values: string[]
}

interface IOKResponse<T> {
    status: 'OK';
    payload: T;
}

interface IErrorResponse {
    status: 'ERROR';
    errors: IError[];
}

export type IResponse<T> = IOKResponse<T> | IErrorResponse;
export type EmptyResponse = IResponse<null>;

export interface IUploadFile {
    id: string;
}
