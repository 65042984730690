import * as React from 'react';

interface IOwnProps {
    visible?: boolean;
    size?: number;
    style?: React.CSSProperties;
}

const image = '/images/loading.svg';

const LoadingIcon: React.SFC<IOwnProps> = (props) => {
    const { size = 50 } = props; 
    const { visible = true } = props;
    if (visible === true) {
        return (
            <div style={props.style}>
                <img src={image} style={{ width: size, height: size }} />
            </div>
        );
    }
    return null;
};
    
export default LoadingIcon;
