import * as Connection from './connection';
import * as Common from './common';


export interface IAuthenticationToken {
    token: string;
}

export interface IUserInfo {
    userID: string;
    name: string;
    eMail: string;
    role: Common.UserRole;
    hasModuleInCharge: boolean;
}

export interface ITrayInfo {
    kind: Common.TrayKind;
    name: string;
    icon: string;
    count: number;
    unreadCount: number;
}

export interface IGetTrays {
    trays: ITrayInfo[];
    canCreateMessage: boolean;
}

export interface IMessageInfo {
    userMessageID: number;
    date: Date;
    from?: string;
    subject: string;
}

export interface IMessage {
    userMessageID: number;
    from: string;
    to: string[];
    isNew: boolean;
    subject: string;
    content: string;
    date: Date;
    canReply: boolean;
    canForward: boolean;
    canDelete: boolean;
}

export interface IMessages {
    totalMessages: number;
    page: number;
    totalPages: number;
    messagesPerPage: number;
    showFrom: number;
    unreadCount: number;
    messages: IMessage[];
}

export interface IProfile {
    eMail: string;
    name: string;
    rut: number;
    role: Common.UserRole;
    address?: string;
    birthdate?: Date;
    gender?: Common.Gender;
    civilStatus?: Common.CivilStatus;
    showeMail: boolean;
    eMailVerified: boolean;
    neweMail?: string;
    groups: string[];
    functions: Array<{ name: string[], value: string }>;
}

export interface IEntityTarget {
    id: string;
    name: string;
    scopeName?: string;
    isUser: boolean;
}

export interface IViewMessage {
    userMessageID: number;
    hasNewChanged: boolean;
}

export interface IAvailableEntitiesForMessage {
    entities: IEntityTarget[];
}

export async function login(eMail: string, password: string) {
    const response = await Connection.post('/Account/Login', { eMail, password });
    return response.data as Common.IResponse<IAuthenticationToken>;
}

export async function getInformation() {
    const response = await Connection.get('/Account/GetInformation');
    return response.data as Common.IResponse<IUserInfo>;
}

export async function getTrays() {
    const response = await Connection.get('/Account/GetTrays')
    return response.data as Common.IResponse<IGetTrays>;
}

export async function getMessages(tray: Common.TrayKind, page: number, messagesPerPage?: number) {
    const response = await Connection.post('/Account/GetMessages', { tray, page, messagesPerPage });
    return response.data as Common.IResponse<IMessages>;
}

export async function getMessage(userMessageID: number) {
    const response = await Connection.get('/Account/GetMessage', { userMessageID });
    return response.data as Common.IResponse<{ message: IMessage }>;
}

export async function getUnreadMessageCount() {
    const response = await Connection.get('/Account/GetUnreadMessageCount');
    return response.data as Common.IResponse<{ count: number }>;
}

export async function getLastUnreadMessages() {
    const response = await Connection.get('/Account/GetLastUnreadMessages');
    return response.data as Common.IResponse<{ messages: IMessageInfo[] }>;
}

export async function viewMessage(userMessageID: number) {
    const response = await Connection.post('/Account/ViewMessage', { userMessageID });
    return response.data as Common.IResponse<IViewMessage>;
}

export async function deleteMessage(userMessageID: number) {
    const response = await Connection.post('/Account/DeleteMessage', { userMessageID });
    return response.data as Common.EmptyResponse;
}

export async function sendMessage(to: string[], subject: string, content: string, replyMessageID?: number) {
    const response = await Connection.post('/Account/SendMessage', { to, subject, content, replyMessageID });
    return response.data as Common.EmptyResponse;
}

export async function getAvailableEntitiesForMessage() {
    const response = await Connection.get('/Account/GetAvailableEntitiesForMessage');
    return response.data as Common.IResponse<IAvailableEntitiesForMessage>;
}

export async function getProfile() {
    const response = await Connection.get('/Account/GetProfile');
    return response.data as Common.IResponse<IProfile>;
}

export async function changeAvatar(uploadFileID?: string) {
    const response = await Connection.post('/Account/ChangeAvatar', { uploadFileID });
    return response.data as Common.EmptyResponse;
}

export async function changeMail(neweMail?: string) {
    const response = await Connection.post('/Account/ChangeMail', { neweMail });
    return response.data as Common.EmptyResponse;
}

export async function changeMailVisibility(hidden: boolean) {
    const response = await Connection.post('/Account/ChangeMailVisibility', { hidden });
    return response.data as Common.EmptyResponse;
}

export async function changePassword(oldPassword: string, newPassword: string) {
    const response = await Connection.post('/Account/ChangePassword', { oldPassword, newPassword });
    return response.data as Common.EmptyResponse;
}

export async function changeInformation(name: string, birthdate?: Date, gender?: Common.Gender, civilStatus?: Common.CivilStatus, address?: string) {
    const response = await Connection.post('/Account/ChangeInformation', { name, birthdate, gender, civilStatus, address });
    return response.data as Common.EmptyResponse;
}

export async function requestMailVerification() {
    const response = await Connection.post('/Account/RequestMailVerification');
    return response.data as Common.EmptyResponse;
}

export async function verifyMail(userID: string, hash: string) {
    const response = await Connection.post('/Account/VerifyMail', { userID, hash });
    return response.data as Common.EmptyResponse;
}

export async function register(rut: number, name: string, eMail: string, password: string) {
    const response = await Connection.post('/Account/Register', { rut, name, eMail, password });
    return response.data as Common.IResponse<{ action: 'Created' | 'Requested' }>;
}

export async function requestRecovery(eMail: string) {
    const response = await Connection.post('/Account/RequestRecovery', { eMail });
    return response.data as Common.EmptyResponse;
}

export async function checkRequestRecovery(userID: string, hash: string) {
    const response = await Connection.post('/Account/CheckRequestRecovery', { userID, hash });
    return response.data as Common.EmptyResponse;
}

export async function recoveryPassword(userID: string, hash: string, newPassword: string) {
    const response = await Connection.post('/Account/RecoveryPassword', { userID, hash, newPassword });
    return response.data as Common.EmptyResponse;
}
