import * as Connection from './connection';
import * as Common from './common';


export interface IDocumentInfo {
    documentID: number;
    date?: Date;
    title: string;
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

export interface IDocumentList {
    canCreate: boolean;
    moduleID: number;
    items: IDocumentInfo[];
    page: number;
    totalPages: number;
    itemsPerPage: number;
    total: number;
}

export interface IDocument {
    documentID: number;
    date: Date;
    title: string;
    description: string;
    hasFile: boolean
    url: string;
}

export interface IEditDocument {
    documentID: number;
    moduleID: number;
    title: string;
    description: string;
    url?: string;
    newUrl?: string;
}

export async function getList(moduleID: number, page: number, messagePerPage?: number) {
    const response = await Connection.post('/Document/GetList', { moduleID, page, messagePerPage });
    return response.data as Common.IResponse<IDocumentList>;
}

export async function get(documentID: number) {
    const response = await Connection.post('/Document/Get', { documentID });
    return response.data as Common.IResponse<IDocument>;
}

export async function edit(documentID: number) {
    const response = await Connection.post('/Document/Edit', { documentID });
    return response.data as Common.IResponse<IEditDocument>;
}

export async function save(document: IEditDocument) {
    const response = await Connection.post('/Document/Save', { ...document });
    return response.data as Common.EmptyResponse;
}

export async function remove(documentID: number) {
    const response = await Connection.post('/Document/Remove', { documentID });
    return response.data as Common.EmptyResponse;
}
