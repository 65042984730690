import * as Connection from './connection';
import * as Common from './common';

export interface IMapInfo {
    name: string;
    defaultZoom: number;
    initialPosition: Common.IGeoPosition;
}
export interface IIssueInfo {
    issueID: number;
    position: Common.IGeoPosition;
    priority: Common.IssuePriority;
    status: Common.IssueAction;
    title: string;
    hasPictures: boolean;
    createdDate: Date;
}
export interface ITag {
    issueTagID: number;
    token: string;
}
export interface IIssue {
    issueID: number;
    title: string;
    priority: Common.IssuePriority;
    status: Common.IssueAction;
    description: string;
    classification: string;
    classificationID: string;
    classificationPath: string;
    createdUserID: string; // Guid
    assignedUserID?: string; // Guid
    createdDate: Date;
    startDate?: Date;
    estimatedEndDate?: Date;
    endDate?: Date;
    closeDate?: Date;
    picturesCount: number;
    commentsCount: number;
    tags: ITag[];

    canAssign: boolean;
    canStart: boolean;
    canStop: boolean;
    canClose: boolean;
    canReject: boolean;
}
export interface IUserDescription {
    userID: string;
    name: number;
}
export interface IIssueForParent {
    issueID: number;
    title: string;
}

export interface IFilterMap {
    moduleID: number;
    statuses?: Common.IssueAction[];
    priorities?: Common.IssuePriority[];
    tags?: string[];
    search?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface IComment {
    userID: string;
    date: Date;
    content: string;
    isOwn: boolean;
}

export interface IComments {
    comments: IComment[];
}

export interface IAssignablesUsers {
    users: IUserDescription[];
}

export async function getMapInfo(moduleID?: number) {
    const response = await Connection.post('/Issue/GetMapInfo', { moduleID });
    return response.data as Common.IResponse<IMapInfo>;
}

export async function getMapIssues(issues: number[]) {
    const response = await Connection.post('/Issue/GetMapIssues', { issues });
    return response.data as Common.IResponse<IIssueInfo[]>;

}

export async function detail(issueID: number) {
    const response = await Connection.post('/Issue/Detail', { issueID });
    return response.data as Common.IResponse<IIssue>;
}

export async function getAssignableUser(issueID: number) {
    const response = await Connection.post('/Issue/GetAssignablesUsers', { issueID });
    return response.data as Common.IResponse<IAssignablesUsers>;
}

export async function assign(issueID: number, userID: number, priority: Common.IssuePriority) {
    const response = await Connection.post('/Issue/Assign', { issueID, userID, priority });
    return response.data as Common.EmptyResponse;
}

export async function start(issueID: number, estimatedDate: Date, tags: string[], description: string) {
    const response = await Connection.post('/Issue/Start', { issueID, estimatedDate, tags, description });
    return response.data as Common.EmptyResponse;
}

export async function stop(issueID: number, resolution: Common.IssueResolution, textComment: string) {
    const response = await Connection.post('/Issue/Stop', { issueID, resolution, comment: textComment });
    return response.data as Common.EmptyResponse;
}

export async function close(issueID: number) {
    const response = await Connection.post('/Issue/Close', { issueID });
    return response.data as Common.EmptyResponse;
}

export async function reject(issueID: number, textComment: string) {
    const response = await Connection.post('/Issue/Reject', { issueID, comment: textComment });
    return response.data as Common.EmptyResponse;
}

export async function comments(issueID: number) {
    const response = await Connection.post('/Issue/Comments', { issueID });
    return response.data as Common.IResponse<IComments>;
}

export async function sendComment(issueID: number, message: string) {
    const response = await Connection.post('/Issue/SendComment', { issueID, message });
    return response.data as Common.EmptyResponse;
}

export async function searchForParent(issueID: number, criteria: string) {
    const response = await Connection.post('/Issue/SearchForParent', { issueID, criteria });
    return response.data as Common.IResponse<IIssueForParent[]>;
}

/*
    import Hubs from './hubs';
    import { setTimeout } from 'timers';
    import { HubConnection } from '@aspnet/signalr';

     Conectar al hub Issue.
    let retry: number = 0;
    let cnn: HubConnection;

    function retryConnect() {
        if (retry === 0) {
            retry = 1;
        }
        else if (retry === 1) {
            retry = 2;
        } else if (retry < 20) {
            retry = retry * 2;
        }
        connectIssue();
        console.log('reconnect');
        setTimeout(retryConnect, retry * 1000)
    }


    function connectIssue() {
        console.log('connectIssue');
        cnn.start()
            .then(() => {
                retry = 0;
                cnn.onclose((err) => {
                    console.log('disconnect');
                    retryConnect();
                });
            })
            .catch(retryConnect);
    }

    Connection.getHubConnection('Issue/Hub').then(x => {
        cnn = x;
        connectIssue();
    });

    Hubs.connect('/Issue/H4ub');
*/
