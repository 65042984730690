import * as Connection from './connection';
import * as Common from './common';


export interface IRequest {
    requestPositionID: number;
    startDate: Date;
    endDate: Date;
    targetUsersCount: number;
    readedUsersCount: number;
    answeredUsersCount: number;
    rejectedUsersCount: number;
    canEdit: boolean;
    canRemove: boolean;
    canView: boolean;
}

export interface IRequests extends Common.IPagination<IRequest> {
    canCreate: boolean;
}

export interface IEditRequest {
    requestPositionID: number;
    message: string;
    hours: number;
    minHours: number;
    requireImage: boolean;
    targets: string[];
    initialDate?: Date;
}

export interface IUser {
    userID: string;
    name: string;
}

export interface IAvailableUsers {
    users: IUser[];
}

export async function list(page: number, itemsPerPage?: number) {
    const response = await Connection.post('/Position/List', { page, itemsPerPage });
    return response.data as Common.IResponse<IRequests>;
}

export async function getAvailableUsers() {
    const response = await Connection.get('/Position/GetAvailableUsers');
    return response.data as Common.IResponse<IAvailableUsers>;
}

export async function getEdit(requestPositionID: number) {
    const response = await Connection.post('/Position/GetEdit', { requestPositionID });
    return response.data as Common.IResponse<IEditRequest>;
}

export async function save(request: IEditRequest) {
    const response = await Connection.post('/Position/Save', { ...request });
    return response.data as Common.EmptyResponse;
}

export async function remove(requestPositionID: number) {
    const response = await Connection.post('/Position/Remove', { requestPositionID });
    return response.data as Common.EmptyResponse;
}
