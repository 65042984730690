import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from './store/configure-store';
import * as serviceWorker from './serviceWorker';
import LoadingIcon from './components/loading-icon';

const App = React.lazy(() => import('./layout/app'));
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
export const history = createBrowserHistory({ basename: baseUrl });
const initialState = (window as any).initialReduxState ? (window as any).initialReduxState : {};
const store = configureStore(history, initialState);
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <React.Suspense fallback={<div style={{ top: 0, left: 0, right: 0, bottom: 0, display: 'flex', position: 'absolute', justifyContent: 'center', alignItems: 'center', background: '#e9ebee' }}><LoadingIcon size={75} visible={true} /></div>}>
                <App />
            </React.Suspense>
        </ConnectedRouter>
    </Provider>,
    rootElement
);

/* ReactDOM.render(
<LocaleProvider locale={esES}>
    <Provider store={store}>
        <ConnectedRouter history={history}/>
    </Provider>
</LocaleProvider>,
rootElement
);
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
