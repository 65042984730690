import { Store } from 'redux';
import { IAppState } from '.';
import { throttle } from 'lodash';


type selectorCallback = (state: IAppState) => any;

interface IStoreType {
    key: string,
    selector: selectorCallback
}

const sessionStore: IStoreType[] = [];
const localStore: IStoreType[] = [];

function storeListener(store: Store<IAppState>): void {
    const state = store.getState();

    for (const { key, selector } of sessionStore) {
        const value = JSON.stringify(selector(state));
        if (value !== undefined) {
            window.sessionStorage.setItem(key, value);
        } else {
            window.sessionStorage.removeItem(key);
        }
    }

    for (const { key, selector } of localStore) {
        const value = JSON.stringify(selector(state));
        if (value !== undefined) {
            window.localStorage.setItem(key, value);
            window.sessionStorage.setItem(key, value);
        } else {
            window.sessionStorage.removeItem(key);
            window.localStorage.removeItem(key);
        }
    }
}

export function AddLocalProperty(key: string, selector: selectorCallback): void {
    localStore.push({ key, selector });
}

export function AddSessionProperty(key: string, selector: selectorCallback): void {
    sessionStore.push({ key, selector });
}

export function LoadProperty<T>(key: string): T | undefined;
export function LoadProperty<T>(key: string, defaultValue: T): T;
export function LoadProperty<T>(key: string, defaultValue?: T): T | undefined {
    const value = window.sessionStorage.getItem(key) || window.localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    }
    return defaultValue;
}

// Subscribe el guardado en el Store de la aplicación.
export function AttachInStore(store: Store<any>): void {
    store.subscribe(throttle(() => storeListener(store), 500));
}