import * as hist from 'history';
import { ReducersMapObject, Middleware, StoreEnhancer, combineReducers, applyMiddleware, compose, createStore } from 'redux';
import * as AppStore from '.';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import { AttachInStore } from './save-state';


export default function configureStore(history: hist.History, initialState: any) {
    const reducers: ReducersMapObject = {
        scope: AppStore.Scope.reducer,
        account: AppStore.Account.reducer,
        authorization: AppStore.Authorization.reducer,
    };

    const middleware: Middleware[] = [
        thunk,
        routerMiddleware(history)
    ];

    const enhancers: StoreEnhancer[] = [
    ];

    if (process.env.NODE_ENV === 'development') {
        const win: any = window as any;
        const devTool: any = win.__REDUX_DEVTOOLS_EXTENSION__ ? win.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;
        enhancers.push(devTool);
    }


    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    )

    // Guarda los estados.
    AttachInStore(store);
    return store;
}