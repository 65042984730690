import * as Connection from './connection';
import * as Common from './common';

export interface IPublicProfile {
    name: string;
    role: Common.UserRole;
    hasAvatar: boolean;
    eMail?: string;
}

export interface IUserOrGroup {
    id: string;
    name: string;
    isUser: boolean;
}

export interface IGetAvailableUsersAndGroups {
    entities: IUserOrGroup[];
}

export async function publicProfile(userID: string) {
    const response = await Connection.post('/User/PublicProfile', { userID });
    return response.data as Common.IResponse<IPublicProfile>;
}

export async function getAvailableUsersAndGroups() {
    const response = await Connection.get('/User/GetAvailableUsersAndGroups');
    return response.data as Common.IResponse<IGetAvailableUsersAndGroups>;
}
