import { Connection, Common } from '.';

export interface IStatistics {
    activeCount: number;
    disabledCount: number;
}

interface IMember {
    id: string;
    name: string;
    isDisabled: boolean;
    isUser: boolean;
}

interface IGetMembersForGroup extends Array<IMember> { };

export interface IGroupInfo {
    groupID: string;
    name: string;
    isDisabled: boolean;
}

export interface IEditScoped {
    groupID: string;
    name: string;
    isDisabled: boolean;
    members: string[];
}

export async function getStatistics() {
    const response = await Connection.get('/Admin/Group/GetStatistics');
    return response.data as Common.IResponse<IStatistics>;
}

export async function getAvailableMembersForGroup(fromGroupID: string) {
    const response = await Connection.get('/Admin/Group/GetAvailableMembersForGroup', { fromGroupID });
    return response.data as Common.IResponse<IGetMembersForGroup>;
}

export async function getList(page: number, itemsPerPage?: number) {
    const response = await Connection.post('/Admin/Group/GetList', { page, itemsPerPage });
    return response.data as Common.IResponse<Common.IPagination<IGroupInfo>>;
}

export async function edit(groupID: string) {
    const response = await Connection.get('/Admin/Group/Edit', { groupID });
    return response.data as Common.IResponse<IEditScoped>;
}

export async function save(group: IEditScoped) {
    const response = await Connection.post('/Admin/Group/Save', { ...group });
    return response.data as Common.EmptyResponse;
}

export async function remove(groupID: string) {
    const response = await Connection.post('/Admin/Group/Remove', { groupID });
    return response.data as Common.EmptyResponse;
}