import { ActionCreatorsMapObject, Reducer } from "redux";
import { LoadProperty, AddSessionProperty } from "./save-state";
import { Connection, Account } from "../api";

const TOKEN_DURATION = 1000 * 60 * 60 ** 23;

enum ActionTypes {
    loginRequest = 'AUTHORIZATION_LOGIN_REQUEST',
    loginCompleted = 'AUTHORIZATION_LOGIN_COMPLETED',
    loginError = 'AUTHORIZATION_LOGIN_ERROR',
    logout = 'AUTHORIZATION_LOGOUT',
}

export interface IState {
    readonly isLogin: boolean;  // Indica si el usuario esta autorizado.
    readonly inLogin: boolean;  // Indica si esta en proceso de autorización.

    readonly loginError?: string;  // Contiene el último mensaje de error.

    readonly token?: string;  // Token de autorización.
    readonly tokenValidDate?: number; // Fecha de termino de autorización.
}

export interface IActions extends ActionCreatorsMapObject {
    login: any;
    logout: any;
}

export const actions: IActions = {
    login: (eMail: string, password: string) => async (dispatch: any, getState: any) => {
        dispatch({ type: ActionTypes.loginRequest })
        const response = await Account.login(eMail, password);
        if (response.status === 'OK') {
            Connection.setAuthorization(response.payload.token);
            dispatch({ type: ActionTypes.loginCompleted, payload: { token: response.payload.token, tokenValidDate: Date.now() + TOKEN_DURATION } });
            return true;
        } else {
            dispatch({ type: ActionTypes.loginError, error: response.errors[0].values[0] });
            return false;
        }
    },
    logout: () => async (dispatch: any, getState: any) => {
        dispatch({ type: ActionTypes.logout })
    }
}

export const reducer: Reducer<IState, any /*StoreAction<ActionTypes>*/> = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case ActionTypes.loginRequest: {
            return {
                ...state,
                inLogin: true,
                loginError: undefined
            }
        }
        case ActionTypes.loginCompleted: {
            return {
                ...state,
                inLogin: false,
                isLogin: true,
                loginError: undefined,
                token: action.payload.token,
                tokenValidDate: action.payload.tokenValidDate
            }
        }

        case ActionTypes.logout: {
            return {
                ...state,
                inLogin: false,
                isLogin: false,
                loginError: undefined,
                token: undefined,
                tokenValidDate: undefined,
            }
        }

        case ActionTypes.loginError: {
            return {
                ...state,
                inLogin: false,
                isLogin: false,
                loginError: action.error
            }
        }
        default:
            return state;
    }
}

const initialState: IState = {
    inLogin: false,
    isLogin: LoadProperty<boolean>('AuthorizationIsLogin', false),
    token: LoadProperty('AuthorizationToken'),
    tokenValidDate: LoadProperty('AuthorizationValidDate')
}

if (initialState.isLogin) {
    Connection.setAuthorization(initialState.token);
}

AddSessionProperty('AuthorizationIsLogin', state => state.authorization.isLogin);
AddSessionProperty('AuthorizationToken', state => state.authorization.token);
AddSessionProperty('AuthorizationValidDate', state => state.authorization.tokenValidDate);