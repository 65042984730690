import { Public, Connection, Common } from '../api';
import { Reducer, ActionCreatorsMapObject } from 'redux';
import { LoadProperty, AddSessionProperty } from './save-state';

// Tipo de acciones.
export enum ActionTypes {
    infoRequest = 'SCOPE_INFO_REQUEST',
    infoCompleted = 'SCOPE_INFO_COMPLETED'
}

// Definiciones.
export interface IState {
    // Identificador de la municipalidad actual.
    readonly scopeID?: string,
    // Origen de la aplicación.
    readonly currentPath: string,
    // Nombre de la municipalidad.
    readonly name?: string,
    // Version de la API.
    readonly version?: number,
    // Url de la municipalidad.
    readonly webUrl?: string,
    // URl de la API especifica de la municipalidad.
    readonly apiUrl?: string
    // Indica si se esta obteniendo la información de la municipalidad.
    readonly inLoading: boolean,
    readonly isLoaded: boolean,
}

export interface IActions extends ActionCreatorsMapObject {
    loadInfo: any;
}

// Acciones.
export const actions: IActions = {
    loadInfo: () => async (dispatch: any, getState: any) => {
        const scope = getState().scope;
        if (scope.inLoading || scope.isLoaded) {
            return; // no carga si esta siendo cargada, ni tampoco si ya esta cargada.
        }
        dispatch({ type: ActionTypes.infoRequest });
        await Common.run(() => Public.findScope(scope.currentPath), (p: any) => {
            Common.run(() => Public.scope(p.scopeID),
                (payload: any) => {
                    Connection.setScope(payload.apiUrl);
                    dispatch({ type: ActionTypes.infoCompleted, payload });
                }
            );
        });
    }
};

// Función reductora.
export const reducer: Reducer<IState, any> = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case ActionTypes.infoRequest:
            return {
                ...state,
                inLoading: true,
                isLoaded: false,
            }
        case ActionTypes.infoCompleted:
            return {
                ...state,
                inLoading: false,
                isLoaded: true,
                scopeID: action.payload.scopeID,
                name: action.payload.name,
                version: action.payload.version,
                webUrl: action.payload.defaultWebUrl,
                apiUrl: action.payload.apiUrl
            }
        default:
            return state;
    }
}

// Inicialización.
const isValid = LoadProperty('ScopeInformationLocation') === window.location.origin;

const initialState: IState = isValid ?
    {
        currentPath: window.location.origin,
        inLoading: false,
        isLoaded: LoadProperty('ScopeInformationLoaded', false),
        scopeID: LoadProperty('ScopeInformationScopeID'),
        name: LoadProperty('ScopeInformationName'),
        version: LoadProperty('ScopeInformationVersion'),
        webUrl: LoadProperty('ScopeInformationWebUrl'),
        apiUrl: LoadProperty('ScopeInformationApiUrl')
    } : {
        currentPath: window.location.origin,
        inLoading: false,
        isLoaded: false,
    };

Connection.setScope(initialState.apiUrl);
AddSessionProperty('ScopeInformationLocation', state => state.scope.currentPath);
AddSessionProperty('ScopeInformationLoaded', state => state.scope.isLoaded);
AddSessionProperty('ScopeInformationScopeID', state => state.scope.scopeID);
AddSessionProperty('ScopeInformationName', state => state.scope.name);
AddSessionProperty('ScopeInformationVersion', state => state.scope.version);
AddSessionProperty('ScopeInformationWebUrl', state => state.scope.webUrl);
AddSessionProperty('ScopeInformationApiUrl', state => state.scope.apiUrl);