import * as Connection from './connection';
import * as Common from './common';

export interface IModule {
    moduleID: number;
    name: string;
    description: string;
    type: Common.ModuleKind;
    color: string;
}

export interface IPathNode {
    moduleID: number;
    name: string;
}

export interface IPathInfo {
    rootModuleID: number;
    rootName: string;
    rootKind: Common.ModuleKind;
    nodes: IPathNode[];
}

export interface IProperties {
    [key: string]: any
}

export interface ICategory {
    moduleID: number;
    name: string;
    description?: string;
    children: ICategory[];
}

export interface IModuleInfo {
    moduleID: number;
    name: string;
    description?: string;
    type: Common.ModuleKind;
    color: string;
    properties: IProperties;
    children: ICategory[];
}

export async function getModules(parentID?: number) {
    const response = await Connection.post('/Scope/GetModules', { parentID });
    return response.data as Common.IResponse<IModule[]>;
}

export async function getPathInfo(moduleID: number) {
    const response = await Connection.post('/Scope/GetPathInfo', { moduleID });
    return response.data as Common.IResponse<IPathInfo>;
}

export async function getModuleInfo(moduleID: number) {
    const response = await Connection.post('/Scope/GetModuleInfo', { moduleID });
    return response.data as Common.IResponse<IModuleInfo>;
}
